import { StoresBundle } from '@/stores/core/StoreBundle';
import { action, computed, observable } from 'mobx';
import { elasticApi } from '@/api';
import moment from 'moment/moment';
import { numGroup } from '@/utils/numbers';
import { InvoiceEntity } from '@/types/entities/InvoiceEntity';

export default class SingleInvoiceStore extends StoresBundle {
  @observable loading = false;
  @observable state?: InvoiceEntity;

  @computed get stateView() {
    if (!this.state) return [];
    const computed = this.state.getComputed();
    return [
      { name: 'Тип документа', value: computed.docTypeLabel },
      { name: 'ID документа', value: this.state.uuid },
      { name: 'Номер счета-фактуры', value: this.state.serialNumber },
      {
        name: 'Дата выставления',
        value: moment(this.state.invoiceDate).format('DD.MM.YYYY'),
      },
      { name: 'ID исправляемого документа', value: this.state.parentNumber },
      { name: 'ИНН продавца', value: computed.sellerTaxNumber },
      { name: 'ИНН покупателя', value: computed.buyerTaxNumber },
      { name: 'Валюта счета-фактуры', value: this.state.currency },
      {
        name: 'Стоимость товаров с НДС всего',
        value: computed.amountPriceSum,
      },
      { name: 'Сумма НДС', value: computed.taxSum },
      {
        name: 'Статус счета-фактуры',
        value: computed.acceptStatusLabel,
        color: computed.acceptStatusColor,
      },
    ];
  }

  @computed get feesView() {
    return (
      this.state?.fees.map((item) => ({
        ...item,
        price: numGroup(item.price),
        amountPrice: numGroup(item.amountPrice),
        taxAmount: numGroup(item.taxAmount),
        priceItems: numGroup(item.priceItems),
      })) || []
    );
  }

  @action.bound destroy() {
    this.state = undefined;
  }

  @action.bound async fetch(uuid: string) {
    this.loading = true;
    try {
      const response = await elasticApi.post('invoice_index/_search', {
        query: {
          match: {
            uuid: uuid,
          },
        },
      });
      this.state = response?.data?.data[0]
        ? new InvoiceEntity(response?.data?.data[0])
        : undefined;
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  }
}
