import { StoresBundle } from '@/stores/core/StoreBundle';
import { action, computed, makeObservable, observable } from 'mobx';
import { Stores } from '@/stores/index';
import moment from 'moment/moment';

export default class PeriodStore extends StoresBundle {
  @observable range: { start: Date | null; end: Date | null } = {
    start: null,
    end: null,
  };
  constructor(stores: Stores) {
    super(stores);
    makeObservable(this);
  }

  @computed get formattedRange() {
    return {
      start: this.range.start
        ? moment(this.range.start).format('DD.MM.YY')
        : '',
      end: this.range.end ? moment(this.range.end).format('DD.MM.YY') : '',
    };
  }

  @action.bound setStartDate(date: Date | null) {
    this.range.start = date;
  }

  @action.bound setEndDate(date: Date | null) {
    this.range.end = date;
  }
}
