
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class IndexView extends Vue {
  CREDITS = {
    LOGIN: 'admin',
    PASSWORD: 'admin123',
  };
  login = '';
  password = '';

  onSubmit() {
    if (
      this.login === this.CREDITS.LOGIN &&
      this.password === this.CREDITS.PASSWORD
    ) {
      localStorage.setItem('authorized', 'true');
      this.$router.push('/app');
    }
  }
}
