import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import IndexView from '@/views/index.vue';

import AppView from '@/views/app.vue';
import AppIndexView from '@/views/app/index.vue';

const InvoicesView = () => import('@/views/app/invoices.vue');
const InvoicesIndexView = () => import('@/views/app/invoices/index.vue');
const InvoicesUuidView = () => import('@/views/app/invoices/_uuid.vue');
const DeclarationsView = () => import('@/views/app/declarations.vue');

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'index',
    component: IndexView,
  },
  {
    path: '/app',
    component: AppView,
    children: [
      {
        path: '',
        name: 'app-index',
        component: AppIndexView,
        redirect: { name: 'app-invoices-index' },
      },
      {
        path: 'invoices',
        component: InvoicesView,
        children: [
          {
            path: '',
            name: 'app-invoices-index',
            component: InvoicesIndexView,
          },
          {
            path: ':uuid',
            name: 'app-invoices-uuid',
            component: InvoicesUuidView,
          },
        ],
      },
      {
        path: 'declarations',
        name: 'app-declarations',
        component: DeclarationsView,
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
