
import { Component, Vue } from 'vue-property-decorator';
import PeriodSelector from '@/components/PeriodSelector.vue';

@Component({
  components: { PeriodSelector },
})
export default class AppView extends Vue {
  asideItems = [
    {
      label: 'Счета-фактуры',
      to: '/app/invoices',
      icon: 'reference-books',
    },
    {
      label: 'Декларации',
      to: '/app/declarations',
      icon: 'reports',
    },
  ];

  beforeMount() {
    if (this.$route.query.key === 'hxygKbvT') {
      localStorage.setItem('authorized', 'true');
    }
    const key = localStorage.getItem('authorized');
    if (!key || key !== String(true)) {
      this.$router.push('/');
    }
  }
}
