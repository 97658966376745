import { Stores } from '@/stores';
import { observable } from 'mobx';

export abstract class StoresBundle {
  @observable stores: Stores;

  constructor(stores: Stores) {
    this.stores = stores;
  }
}
