import { StoresBundle } from '@/stores/core/StoreBundle';
import { action, computed, observable, reaction, values } from 'mobx';
import { Declaration } from '@/types/Declaration';
import { generatorApi } from '@/api';
import { Stores } from '@/stores/index';
import moment from 'moment';
import { Sort } from '@/stores/InvoicesStore';
import _ from 'lodash';
import {
  DeclarationFilledColor,
  DeclarationFilledLabel,
  DeclarationPayStatusColor,
  DeclarationPayStatusLabel,
  DeclarationSizeLabel,
  DeclarationSurColorLabel,
  DeclarationSurColorStyle,
  DeclarationTaxGapColor,
  DeclarationTaxGapLabel,
  DeclarationTypeLabel,
} from '@/constants';
import { json2csv } from 'json-2-csv';

export default class DeclarationStore extends StoresBundle {
  @observable items: Declaration[] = [];
  @observable loading = false;
  @observable startDate: Date | null = null;
  @observable endDate: Date | null = null;
  @observable filter = '';
  @observable page = 1;
  @observable limit = 20;
  @observable sort: Sort = {
    prop: '',
    direction: null,
  };
  @observable maxPage = 1;
  @observable totalItems = 1;

  private searchCaller: any = null;

  constructor(stores: Stores) {
    super(stores);

    reaction(
      () => values(this.stores.period.range),
      ([start, end]) => {
        this.startDate = start ? new Date(start) : null;
        this.endDate = end ? new Date(end) : null;
        this.fetch();
      }
    );
  }

  @computed get itemsView() {
    return this.items.map((item) => {
      return {
        ...item,
        isDecFilledLabel: DeclarationFilledLabel[item.isDecFiled],
        isDecFilledColor: DeclarationFilledColor[item.isDecFiled],
        decTypeLabel: DeclarationTypeLabel[item.decType],
        decSizeLabel: DeclarationSizeLabel[item.decSize],
        isTaxGapLabel: DeclarationTaxGapLabel[item.isTaxGap],
        isTaxGapColor: DeclarationTaxGapColor[item.isTaxGap],
        payStatusLabel: DeclarationPayStatusLabel[item.payStatus],
        payStatusColor: DeclarationPayStatusColor[item.payStatus],
        surColourLabel: DeclarationSurColorLabel[item.surColour],
        surColourStyle: DeclarationSurColorStyle[item.surColour],
      };
    });
  }

  @action.bound setFilter(value: string) {
    this.filter = value;
    if (this.searchCaller?.cancel) this.searchCaller.cancel();
    this.searchCaller = _.debounce(() => {
      this.searchCaller = null;
      this.fetch();
    }, 1000);
    this.searchCaller();
  }

  @action.bound refresh() {
    this.fetch();
  }

  @action.bound setSort(sort: Sort) {
    this.sort = sort;
    this.page = 1;
    this.fetch();
  }

  @action.bound setPage(page: number) {
    this.page = page;
    this.fetch();
  }

  @action.bound setLimit(limit: number) {
    this.limit = limit;
    this.page = 1;
    this.fetch();
  }

  @action.bound async downloadCSV() {
    this.loading = true;
    try {
      const { items } = await this.fetchData(true);
      const csv = await json2csv(items);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      if (link.download !== undefined) {
        link.setAttribute('href', url);
        link.setAttribute('download', 'Декларации');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (e) {
      console.error('BlobToSaveAs error', e);
    } finally {
      this.loading = false;
    }
  }

  @action.bound async fetch() {
    this.loading = true;
    try {
      const { items, totalItems, maxPage } = await this.fetchData();
      this.items = items || [];
      this.maxPage = maxPage || 1;
      this.totalItems = totalItems || 0;
    } catch (e) {
      this.items = [];
      this.maxPage = 1;
      this.totalItems = 0;
      console.error(e);
    } finally {
      this.loading = false;
    }
  }

  @action.bound async fetchData(ignorePages = false) {
    const params = {
      page: ignorePages ? 1 : this.page,
      limit: ignorePages ? this.totalItems : this.limit,
      ...(this.startDate && {
        startDate: moment(this.startDate).format('DD-MM-yyyy'),
      }),
      ...(this.endDate && {
        endDate: moment(this.endDate).format('DD-MM-yyyy'),
      }),
      ...(this.filter && { filter: this.filter }),
      ...(this.sort.prop &&
        this.sort.direction && {
          sortBy: this.sort.prop,
          direction: this.sort.direction.toUpperCase(),
        }),
    };
    const response = await generatorApi.get('/declarations', { params });

    return {
      items: response.data.items,
      maxPage: response.data.totalPages,
      totalItems: response.data.totalItems,
    };
  }
}
