export const DeclarationFilledLabel = {
  0: 'Подана',
  1: 'Не подана',
};

export const DeclarationFilledColor = {
  0: 'primary',
  1: 'neutral',
};

export const DeclarationTypeLabel = {
  0: 'Не подана',
  1: 'К уплате',
  2: 'К вычету',
  3: 'Нулевая',
};

export const DeclarationSizeLabel = {
  0: 'Малый/средний',
  1: 'Крупный',
};

export const DeclarationTaxGapLabel = {
  0: 'Нет разрыва',
  1: 'Разрыв',
};

export const DeclarationTaxGapColor = {
  0: 'neutral',
  1: 'danger',
};

export const DeclarationPayStatusLabel = {
  0: 'Нет данных',
  1: 'Оплачено',
  2: 'Оплачено частично',
  3: 'Не опалачено',
};

export const DeclarationPayStatusColor = {
  0: 'neutral',
  1: 'success',
  2: 'info',
  3: 'danger',
};

export const DeclarationSurColorLabel = {
  YELLOW: 'Жёлтый',
  GREEN: 'Зелёный',
  RED: 'Красный',
};

export const DeclarationSurColorStyle = {
  YELLOW: 'accent',
  GREEN: 'success',
  RED: 'danger',
};
