import Vue from 'vue';
import VueI18n from 'vue-i18n';
import ruLocale from '@/locales/ru.json';

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en',
  fallbackLocale: 'en',
  messages: {
    ru: { ...ruLocale },
  },
});

function setI18nLanguage(lang: string) {
  i18n.locale = lang;
  localStorage.setItem('lang', lang);
  return lang;
}

export { i18n, setI18nLanguage };
