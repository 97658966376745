export function numGroup(
  value: number | null | undefined,
  incorrectSign = '—'
) {
  if (value === null || value === undefined) return incorrectSign;
  return Number(value.toFixed(2)).toLocaleString();
  // .toString()
  // .split(/(?=(?:...)*$)/)
  // .join(' ');
}

export const getUniqueValuesString = <T>(array: T[], field: keyof T) => {
  const resultArray: any[] = [];
  array.forEach((item) => resultArray.push(item[field]));
  return Array.from(new Set(resultArray)).join(', ');
};
