import axios from 'axios';

const isProduction = process.env.NODE_ENV === 'production';
const token =
  'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJraGFyYXRpYW5fYXJ0aHVyQG1haWwucnUiLCJ0eXBlIjoiQ1VTVE9NRVIiLCJpZCI6MTgzMTEsImxwSWQiOjE4MzE0LCJzY29wZXMiOlsiUk9MRV9DVVNUT01FUiJdLCJpc3MiOiJjZnJzIiwiaWF0IjoxNjg1MDMzNjI3LCJleHAiOjE3MDEyMzM2Mjd9.j503ttRhsNzxfS1dsZO4IjJm5piJUQrJ1NoLgaV3kXLqKjZD4RdU7k8kncUSpyjkn-sf-OfwTbKSl1Y2MYrX6w';
const API_URL = isProduction
  ? 'https://elastic-ext.dev-24x7.com'
  : 'http://10.36.99.196:9200';

const elasticApi = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    ...(isProduction && {
      'access-token': `${token}`,
    }),
  },
  transformResponse: (response) => {
    const data = JSON.parse(response);
    const items = data?.hits?.hits;
    if (items?.length) {
      return {
        data: items.map((item: any) => item._source),
        totalItems: data?.hits?.total?.value || 0,
      };
    }
    return null;
  },
});

const generatorApi = axios.create({
  baseURL: 'https://sto-tax-faktur-generation.dev-24x7.com/api',
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': '68bdfca5-631f-45c2-914f-5917f194df7e',
  },
});

export { elasticApi, generatorApi };
