
import { Component, Vue } from 'vue-property-decorator';
import stores from '@/stores';
import { Observer } from 'mobx-vue';

@Observer
@Component
export default class PeriodSelector extends Vue {
  periodStore = stores.period;
}
