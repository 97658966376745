import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueI18n from 'vue-i18n';

Vue.config.productionTip = false;

import 'normalize.css';

import DttUiKit from '@dtt/ui-kit';
Vue.use(DttUiKit);
Vue.use(VueI18n);

import '@/assets/styles/index.scss';

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
