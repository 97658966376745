import { StoresBundle } from '@/stores/core/StoreBundle';
import { action, computed, observable, reaction, values } from 'mobx';
import { elasticApi } from '@/api';
import { Invoice, InvoiceResponse } from '@/types/Invoice';
import moment from 'moment';
import * as _ from 'lodash';
import { json2csv } from 'json-2-csv';
import { Stores } from '@/stores/index';
import { InvoiceEntity } from '@/types/entities/InvoiceEntity';

export type Sort = {
  prop: string;
  direction: string | null;
};

export default class InvoicesStore extends StoresBundle {
  @observable items: InvoiceEntity[] = [];
  @observable search = '';
  @observable loading = false;
  @observable page = 0;
  @observable pageSize = 20;
  @observable sort: Sort = {
    prop: 'invoiceDateTime',
    direction: 'desc',
  };
  @observable periodFrom: Date | null = null;
  @observable periodTo: Date | null = null;
  @observable totalItems = 0;

  private searchCaller: any = null;

  constructor(stores: Stores) {
    super(stores);

    reaction(
      () => values(this.stores.period.range),
      ([start, end]) => {
        this.periodFrom = start ? new Date(start) : null;
        this.periodTo = end ? new Date(end) : null;
        this.fetch();
      }
    );
  }

  @computed get maxPage() {
    return Math.max(0, Math.ceil(this.totalItems / this.pageSize) - 1);
  }

  @action.bound setSearch(value: string) {
    this.search = value;
    if (this.searchCaller?.cancel) this.searchCaller.cancel();
    this.searchCaller = _.debounce(() => {
      this.searchCaller = null;
      this.fetch();
    }, 1000);
    this.searchCaller();
  }

  @action.bound setPage(page: number) {
    this.page = page;
    this.fetch();
  }

  @action.bound setPageSize(size: number) {
    this.pageSize = size;
    this.page = 0;
    this.fetch();
  }

  @action.bound setSort(sort: Sort) {
    this.sort = sort;
    this.page = 0;
    this.fetch();
  }

  @action.bound setPeriodFrom(date: Date) {
    this.periodFrom = date;
    this.fetch();
  }

  @action.bound setPeriodTo(date: Date) {
    this.periodTo = date;
    this.fetch();
  }

  @action.bound refresh() {
    this.fetch();
  }

  @action.bound async downloadCSV() {
    this.loading = true;
    try {
      const { items } = await this.fetchData(true);
      (items as Invoice[]).forEach((item) => {
        item.parentNumber = item.parentNumber || undefined;
      });
      const csv = await json2csv(items, {
        expandArrayObjects: true,
        excludeKeys: ['_class'],
        parseValue: (fieldValue, defaultParser) => {
          if (Array.isArray(fieldValue)) {
            return fieldValue.join('|');
          }
          return defaultParser(fieldValue);
        },
      });
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      if (link.download !== undefined) {
        link.setAttribute('href', url);
        link.setAttribute('download', 'Счета-фактуры');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (e) {
      console.error('BlobToSaveAs error', e);
    } finally {
      this.loading = false;
    }
  }

  @action.bound
  async fetch() {
    this.loading = true;
    try {
      const { items, totalItems } = await this.fetchData();
      this.items = items.map((item) => new InvoiceEntity(item));
      this.totalItems = totalItems || 0;
    } catch (e) {
      console.error(e);
      this.items = [];
      this.totalItems = 0;
    } finally {
      this.loading = false;
    }
  }

  @action.bound async fetchData(ignorePages = false) {
    const periodFrom = moment(this.periodFrom).format('YYYY-MM-DD');
    const periodTo = moment(this.periodTo).format('YYYY-MM-DD');
    const params: any[] = [];
    if (this.periodFrom || this.periodTo)
      params.push({
        range: {
          invoiceDate: {
            ...(this.periodFrom && { gte: periodFrom }),
            ...(this.periodTo && { lte: periodTo }),
          },
        },
      });
    if (this.search)
      params.push({
        multi_match: {
          query: this.search,
          type: 'phrase_prefix',
          fields: ['serialNumber', 'sellerTaxNumber', 'buyerTaxNumber'],
        },
      });
    const response = await elasticApi.post<InvoiceResponse>(
      'invoice_index/_search',
      {
        from: !ignorePages ? this.page * this.pageSize : 0,
        size: !ignorePages ? this.pageSize : this.totalItems,
        track_total_hits: true,
        ...(this.sort.prop && {
          sort: [{ [this.sort.prop]: this.sort.direction }],
        }),
        query: {
          bool: {
            must: params,
            must_not: [
              {
                term: {
                  ['status.keyword']: 'DECLINED',
                },
              },
            ],
          },
        },
      }
    );
    return {
      items: response.data?.data || [],
      totalItems: response.data?.totalItems || 0,
    };
  }

  @computed get itemsView() {
    return this.items.map((item) => item.getComputed());
  }
}
