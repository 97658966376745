import {
  Fee,
  Invoice,
  InvoiceComputed,
  InvoiceDocType,
  InvoiceStatus,
  InvoiceAcceptStatus,
  InvoiceView,
} from '@/types/Invoice';
import { numGroup } from '@/utils/numbers';
import moment from 'moment/moment';
import { getUniqueValuesString } from '@/utils/numbers';
import invoiceDictionary from '@/types/dictionaries/InvoiceDictionary';

const DEFAULT_DATE_FORMAT = 'DD.MM.yy';
const DEFAULT_VIEW = 'buyer';

export class InvoiceEntity implements Invoice {
  public acceptStatus: InvoiceAcceptStatus;
  public amountPriceSum: number;
  public buyerTaxNumber: string;
  public currency: string;
  public docType: InvoiceDocType;
  public fees: Fee[];
  public invoiceDate: string;
  public invoiceDateTime: string;
  public legalPersonId: number;
  public sellerTaxNumber: string;
  public serialNumber: string;
  public status: InvoiceStatus;
  public taxSum: number;
  public uuid: string;
  public validationErrors: string[];
  public parentNumber?: string;

  constructor(rawInvoice: Invoice) {
    this.acceptStatus = rawInvoice.acceptStatus;
    this.amountPriceSum = rawInvoice.amountPriceSum;
    this.buyerTaxNumber = rawInvoice.buyerTaxNumber;
    this.currency = rawInvoice.currency;
    this.docType = rawInvoice.docType;
    this.fees = rawInvoice.fees;
    this.invoiceDate = rawInvoice.invoiceDate;
    this.invoiceDateTime = rawInvoice.invoiceDateTime;
    this.legalPersonId = rawInvoice.legalPersonId;
    this.sellerTaxNumber = rawInvoice.sellerTaxNumber;
    this.serialNumber = rawInvoice.serialNumber;
    this.status = rawInvoice.status;
    this.taxSum = rawInvoice.taxSum;
    this.uuid = rawInvoice.uuid;
    this.validationErrors = rawInvoice.validationErrors;
    this.parentNumber = rawInvoice.parentNumber;
  }

  public getComputed(view: InvoiceView = DEFAULT_VIEW): InvoiceComputed {
    const viewTin =
      view === 'buyer' ? this.buyerTaxNumber : this.sellerTaxNumber;
    return {
      acceptStatus: this.acceptStatus,
      docType: this.docType,
      fees: this.fees,
      invoiceDateTime: this.invoiceDateTime,
      legalPersonId: this.legalPersonId,
      serialNumber: this.serialNumber,
      status: this.status,
      uuid: this.uuid,
      validationErrors: this.validationErrors,
      tin: invoiceDictionary.getTin(viewTin),
      buyerTaxNumber: invoiceDictionary.getTin(this.buyerTaxNumber),
      sellerTaxNumber: invoiceDictionary.getTin(this.sellerTaxNumber),
      taxSum: numGroup(this.taxSum),
      currency: this.currency || '—',
      amountPriceSum: numGroup(this.amountPriceSum),
      invoiceDate: moment(this.invoiceDate).format(DEFAULT_DATE_FORMAT),
      docTypeLabel: invoiceDictionary.getDocTypeLabel(this.docType),
      docTypeColor: invoiceDictionary.getDocTypeColor(this.docType),
      docName: invoiceDictionary.getCardDocTypeLabel(this.docType),
      acceptStatusLabel: invoiceDictionary.getUnionStatusLabel(
        this.acceptStatus,
        this.status,
        this.docType
      ),
      acceptStatusColor: invoiceDictionary.getUnionStatusColor(
        this.acceptStatus,
        this.status,
        this.docType
      ),
      parentNumber: this.parentNumber ?? '—',
      rate: getUniqueValuesString<Fee>(this.fees, 'taxRate'),
    };
  }
}
