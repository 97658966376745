import InvoicesStore from '@/stores/InvoicesStore';
import DeclarationStore from '@/stores/DeclarationStore';
import PeriodStore from '@/stores/PeriodStore';
import SingleInvoiceStore from '@/stores/SingleInvoiceStore';
import { configure } from 'mobx';
configure({
  useProxies: 'ifavailable',
});

export class Stores {
  period = new PeriodStore(this);
  invoices = new InvoicesStore(this);
  singleInvoice = new SingleInvoiceStore(this);
  declarations = new DeclarationStore(this);
}

const stores = new Stores();

export default stores;
