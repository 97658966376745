import {
  InvoiceAcceptStatus,
  InvoiceDocType,
  InvoiceStatus,
} from '@/types/Invoice';
import { i18n } from '@/i18n';

type KeyList<T extends number | string | symbol> = { [key in T]: string };

class InvoiceDictionary {
  private statusLabel: KeyList<InvoiceStatus> = {
    APPROVED: 'terms.invoice.inner_status.approved',
    DECLINED: 'terms.invoice.inner_status.declined',
    NOT_ACTUAL: 'terms.invoice.inner_status.not_actual',
    VOID: 'terms.invoice.inner_status.void',
    CREATED: 'terms.invoice.inner_status.created',
  };
  private statusColor: KeyList<InvoiceStatus> = {
    APPROVED: 'success',
    DECLINED: 'danger',
    NOT_ACTUAL: 'neutral',
    VOID: 'neutral',
    CREATED: 'info',
  };
  private acceptStatusLabel: KeyList<InvoiceAcceptStatus> = {
    NEW: 'terms.invoice.status.new',
    ACCEPTED: 'terms.invoice.status.accepted',
    AUTO_ACCEPTED: 'terms.invoice.status.auto_accepted',
    REJECTED: 'terms.invoice.status.rejected',
  };
  private acceptStatusColor: KeyList<InvoiceAcceptStatus> = {
    NEW: 'primary',
    ACCEPTED: 'success',
    AUTO_ACCEPTED: 'success',
    REJECTED: 'danger',
  };
  private docTypeLabel: KeyList<InvoiceDocType> = {
    BASIC: 'terms.invoice.type.basic',
    ANNULLING: 'terms.invoice.type.annulling',
    CORRECTIVE_BASIC: 'terms.invoice.type.corrective',
  };
  private docTypeColor: KeyList<InvoiceDocType> = {
    BASIC: 'success',
    ANNULLING: 'neutral',
    CORRECTIVE_BASIC: 'info',
  };
  private cardDocTypeLabel: KeyList<InvoiceDocType> = {
    BASIC: 'terms.invoice.card_type.basic',
    ANNULLING: 'terms.invoice.card_type.annulling',
    CORRECTIVE_BASIC: 'terms.invoice.card_type.corrective',
  };

  public getStatusLabel(value: InvoiceStatus) {
    return this.handle(this.statusLabel[value]);
  }
  public getStatusColor(value: InvoiceStatus) {
    return this.statusColor[value];
  }
  public getAcceptStatusLabel(value: InvoiceAcceptStatus) {
    return this.handle(this.acceptStatusLabel[value]);
  }
  public getAcceptStatusColor(value: InvoiceAcceptStatus) {
    return this.acceptStatusColor[value];
  }
  public getDocTypeLabel(value: InvoiceDocType) {
    return this.handle(this.docTypeLabel[value]);
  }
  public getDocTypeColor(value: InvoiceDocType) {
    return this.docTypeColor[value];
  }
  public getCardDocTypeLabel(value: InvoiceDocType) {
    return this.handle(this.cardDocTypeLabel[value]);
  }
  public getUnionStatusLabel(
    acceptStatus: InvoiceAcceptStatus,
    flcStatus: InvoiceStatus,
    docType?: InvoiceDocType,
    invoiceType?: string
  ) {
    if (
      docType &&
      invoiceType === 'outcome' &&
      ['ANNULLING', 'CORRECTIVE_BASIC'].includes(docType) &&
      acceptStatus === 'NEW' &&
      flcStatus === 'APPROVED'
    )
      return i18n.t('terms.invoice.inner_status.sended') as string;
    return this.handle(
      this.getAcceptStatusLabel(acceptStatus) ||
        this.getStatusLabel(flcStatus) ||
        'terms.invoice.inner_status.no_status'
    );
  }
  public getUnionStatusColor(
    acceptStatus: InvoiceAcceptStatus,
    flcStatus: InvoiceStatus,
    docType?: InvoiceDocType,
    invoiceType?: string
  ) {
    if (
      docType &&
      invoiceType === 'outcome' &&
      ['ANNULLING', 'CORRECTIVE_BASIC'].includes(docType) &&
      acceptStatus === 'NEW' &&
      flcStatus === 'APPROVED'
    )
      return 'primary';
    return (
      this.getAcceptStatusColor(acceptStatus) ||
      this.getStatusColor(flcStatus) ||
      'neutral'
    );
  }
  public getTin(tin: string) {
    return tin === '-' || tin === '—'
      ? this.handle('terms.taxpayer.defaulter')
      : tin;
  }

  private handle(value: string) {
    return i18n.t(value) as string;
  }
}

export default new InvoiceDictionary();
